window.trackMatomoEvent = function(eventName, eventData) {
    if (!window._paq) {
        return;
    }

    window._paq.push(["trackEvent", eventName, eventData]);

    // Log to console if dev instance
    if (document.documentElement.classList.contains("is-dev")) {
        console.log(`MATOMO EVENT TRACKING: ${eventName}: ${eventData}`);
    }
}
